import { getEventCompleteUrl, getEventStartUrl, getLocation } from "../helper";
import { isBoolean } from "../validation";
import { EventActivity } from "./eventActivity";

export class EventTask extends EventActivity {
    constructor() {
        super();
        this.expectImage = false;
    }

    getDifficultyText() {
        switch (this.points) {
            case 2:
                return "Medium";
            case 3:
                return "Hard";
            default:
                return "Easy";
        }
    }
    doesExpectImage() {
        return this.expectImage;
    }
    getClone() {
        return new EventTask().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { id: "", points: 0, title: "", description: "", directlyAvailable: false, secret: "", expectImage: false }) {
        super.applyExport(params);
        this.setExpectImage(params.expectImage);
        return this;
    }
    setExpectImage(expectImage = false) {
        if (!isBoolean(expectImage)) {
            throw new EventTaskError("Invalid parameter type: expectImage");
        }
        this.expectImage = expectImage;
        return this;
    }
    getStartActivityLink(eventId, location = getLocation()) {
        return `${location}${getEventStartUrl(eventId, this.getId())}`;
    }
    getCompleteActivityLink(eventId, location = getLocation()) {
        return `${location}${getEventCompleteUrl(eventId, this.getId(), this.secret)}`;
    }

}

export class EventTaskError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventTaskError";
    }
}