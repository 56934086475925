import { useRef } from "react";
import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { CustomQRSVG } from "../CustomQRSVG";
import "./EventQRExportModal.css";
import { getLocation } from "../../logic/helper";

export function EventQRExportModal({ title = "", visible = false, onClose, eventState, marginSize = 3, targetDomain = getLocation() }) {
    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef, documentTitle: "EventQuest" });

    const getTaskRows = () => {
        const data = [];
        const startTasks = eventState.getTasks().filter((task) => {
            return !task.isDirectlyAvailable();
        }).map((task) => {
            return (
                <Col key={task.getId()}>
                    <Stack>
                        {task.getTitle()}
                        <CustomQRSVG marginSize={marginSize} value={task.getStartActivityLink(eventState.getId(), targetDomain)} title="Start task QR Code" />
                    </Stack>
                </Col>
            )
        });
        if (startTasks.length > 0) {
            data.push(
                <Row key="startTaskHeaderRow">
                    <h3>Start Task QR-Codes</h3>
                </Row>
            );
            data.push(
                <Row key="startTaskDataRow">
                    {startTasks}
                </Row>
            );
        }

        const completeTasks = eventState.getTasks().map((task) => {
            return (
                <Col key={task.getId()}>
                    <Stack>
                        {task.getTitle()}
                        <CustomQRSVG marginSize={marginSize} value={task.getCompleteActivityLink(eventState.getId(), targetDomain)} title="Complete task QR Code" />
                    </Stack>
                </Col>
            )
        });
        if (completeTasks.length > 0) {
            data.push(
                <Row key="completeTaskHeaderRow">
                    <h3>Complete Task QR-Codes</h3>
                </Row>
            );
            data.push(
                <Row key="completeTaskDataRow">
                    {completeTasks}
                </Row>
            );
        }
        return data.flat();
    }
    const getTokenRows = () => {
        const data = [];
        const findTokens = eventState.getTokens().filter((task) => {
            return !task.isDirectlyAvailable();
        }).map((token) => {
            return (
                <Col key={token.getId()}>
                    <Stack>
                        {token.getTitle()}
                        <CustomQRSVG marginSize={marginSize} value={token.getStartActivityLink(eventState.getId(), targetDomain)} title="Find token QR Code" />
                    </Stack>
                </Col>
            )
        });
        if (findTokens.length > 0) {
            data.push(
                <Row key="findTokenHeaderRow">
                    <h3>Find Token QR-Codes</h3>
                </Row>
            );
            data.push(
                <Row key="findTokenDataRow">
                    {findTokens}
                </Row>
            );
        }


        const collectTokens = eventState.getTokens().map((token) => {
            return (
                <Col key={token.getId()}>
                    <Stack>
                        {token.getTitle()} ({token.getToken()})
                        <CustomQRSVG marginSize={marginSize} value={token.getCompleteActivityLink(eventState.getId(), targetDomain)} title="Collect token QR Code" />
                    </Stack>
                </Col>
            )
        });
        if (collectTokens.length > 0) {
            data.push(
                <Row key="collectTokenHeaderRow">
                    <h3>Collect Token QR-Codes</h3>
                </Row>
            );
            data.push(
                <Row key="collectTokenDataRow">
                    {collectTokens}
                </Row>
            );
        }
        return data.flat();
    }


    const getRewardRows = () => {
        const data = [];
        const rewards = eventState.getRewards().map((reward) => {
            return (
                <Col key={reward.getId()}>
                    <Stack>
                        {reward.getTitle()}
                        <CustomQRSVG marginSize={marginSize} value={reward.getCompleteActivityLink(eventState.getId(), targetDomain)} title="Confirm reward QR Code" />
                    </Stack>
                </Col>
            )
        });
        if (rewards.length > 0) {
            data.push(
                <Row key="confirmRewardHeaderRow">
                    <h3>Confirm Reward QR-Codes</h3>
                </Row>
            );
            data.push(
                <Row key="confirmRewardDataRow">
                    {rewards}
                </Row>
            );
        }
        return data.flat();
    }


    return (
        <Modal show={visible} onHide={() => { onClose() }} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>{title} - {targetDomain}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container ref={contentRef}>
                    <Row key="eventJoinRow">
                        <Col xs={12} md={8}>
                            <Stack>
                                <h3>Join the Event</h3>
                                <CustomQRSVG marginSize={marginSize} value={eventState.getPreviewLink(targetDomain)} title="Join Event QR Code" variant="large" />
                            </Stack>
                        </Col>
                    </Row>
                    {getTaskRows()}
                    {getTokenRows()}
                    {getRewardRows()}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { onClose() }}>Close</Button>
                <Button variant="primary" onClick={reactToPrintFn}>Print</Button>
            </Modal.Footer>
        </Modal >
    );
}