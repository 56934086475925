import { getEventCollectUrl, getEventFindUrl, getLocation } from "../helper";
import { isString } from "../validation";
import { EventActivity } from "./eventActivity";

export class EventToken extends EventActivity {
    constructor() {
        super();
        this.token = "";
        this.hint = "";
    }

    getToken() {
        return this.token;
    }
    getHint() {
        return this.hint;
    }
    getClone() {
        return new EventToken().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { id: "", points: 0, title: "", description: "", directlyAvailable: false, secret: "", token: "", hint: "" }) {
        super.applyExport(params);
        this.setToken(params.token);
        this.setHint(params.hint);
        return this;
    }
    setToken(token = "") {
        if (!isString(token)) {
            throw new EventTokenError("Invalid parameter type: token");
        }
        this.token = token;
        return this;
    }
    setHint(hint = "") {
        if (!isString(hint)) {
            throw new EventTokenError("Invalid parameter type: hint");
        }
        this.hint = hint;
        return this;
    }
    getStartActivityLink(eventId, location = getLocation()) {
        return `${location}${getEventFindUrl(eventId, this.getId())}`;
    }
    getCompleteActivityLink(eventId, location = getLocation()) {
        return `${location}${getEventCollectUrl(eventId, this.getId(), this.secret)}`;
    }
}

export class EventTokenError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventTokenError";
    }
}