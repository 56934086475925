import { EventId } from "./eventId";
import { isArray, isInteger, isObject, isString } from "../validation";
import { EventTask } from "./eventTask";
import { EventReward } from "./eventReward";
import { EventToken } from "./eventToken";
import { existsInArray, filterFromArray, getFromArray, getFromArrayById, getNow, getLocalDateOnly, updateInArray, getTomorrow, getLocation, getEventBaseUrl } from "../helper";

export class EventDefinition extends EventId {
    constructor() {
        super();
        this.title = "";
        this.description = "";
        this.joincode = "";
        this.image = "";
        this.version = 0;
        this.start = getLocalDateOnly(getNow());
        this.end = getLocalDateOnly(getTomorrow(this.start));

        this.tasks = []; //EventTask
        this.rewards = []; //EventRewards
        this.tokens = []; //EventToken
    }

    getTitle() {
        return this.title;
    }
    getDescription() {
        return this.description;
    }
    getJoinCode() {
        return this.joincode;
    }
    getImage() {
        return this.image;
    }
    getStart() {
        return this.start;
    }
    getEnd() {
        return this.end;
    }
    getDuration() {
        return this.end - this.start;
    }
    getAvailableFor() {
        return this.end - getNow();
    }
    hasStarted() {
        return getNow() >= this.start;
    }
    hasEnded() {
        return getNow() > this.end;
    }
    isActive() {
        return this.hasStarted() && !this.hasEnded();
    }
    getTasks() {
        return this.tasks;
    }
    getRewards() {
        return this.rewards;
    }
    getTokens() {
        return this.tokens;
    }
    getVersion() {
        return this.version;
    }
    wasUpdated(comparedVersion) {
        return this.version < comparedVersion;
    }
    getPreviewLink(location = getLocation()) {
        return `${location}${getEventBaseUrl(this.getId())}`;
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new EventDefinition().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { id: "", title: "", description: "", joincode: "", image: "", version: 0, start: 0, end: 0, tasks: [], rewards: [], tokens: [] }) {
        this.setId(params.id);
        this.setTitle(params.title);
        this.setDescription(params.description);
        this.setJoinCode(params.joincode || "");
        this.setImage(params.image);
        this.setVersion(params.version);
        this.setStart(params.start);
        this.setEnd(params.end);
        this.setTasks(params.tasks);
        this.setRewards(params.rewards || params.goals); // goals was the old name for this - allow backwards compatibility with old files
        this.setTokens(params.tokens);
        return this;
    }

    setJoinCode(joincode = "") {
        if (!isString(joincode)) {
            throw new EventDefinitionError("Invalid parameter type: joincode");
        }
        this.joincode = joincode;
        return this;
    }
    setTitle(title = "") {
        if (!isString(title)) {
            throw new EventDefinitionError("Invalid parameter type: title");
        }
        this.title = title;
        return this;
    }
    setDescription(description = "") {
        if (!isString(description)) {
            throw new EventDefinitionError("Invalid parameter type: description");
        }
        this.description = description;
        return this;
    }
    setImage(image = "") {
        if (!isString(image)) {
            throw new EventDefinitionError("Invalid parameter type: image");
        }
        this.image = image;
        return this;
    }
    updateVersion() {
        this.version += 1;
        return this;
    }
    setVersion(version = 0) {
        if (!isInteger(version)) {
            throw new EventDefinitionError("Invalid parameter type: version");
        }
        this.version = version;
        return this;
    }
    setStart(start = 0) {
        if (!isInteger(start)) {
            throw new EventDefinitionError("Invalid parameter type: start");
        }
        this.start = start;
        return this;
    }
    setEnd(end = 0) {
        if (!isInteger(end)) {
            throw new EventDefinitionError("Invalid parameter type: end");
        }
        this.end = end;
        return this;
    }
    setTasks(tasks) {
        if (!isArray(tasks)) {
            throw new EventDefinitionError("Invalid parameter type: tasks");
        }
        this.tasks = [];
        for (let i = 0; i < tasks.length; i++) {
            this.addTask(tasks[i]);
        }
        return this;
    }
    removeTaskById(taskId) {
        this.tasks = filterFromArray(this.tasks, taskId);
        return this;
    }
    getTaskById(taskId) {
        return getFromArrayById(this.tasks, taskId);
    }
    getTask(taskObj) {
        return getFromArray(this.tasks, taskObj);
    }
    updateTask(taskObj) {
        this.tasks = updateInArray(this.tasks, taskObj);
        return this;
    }
    knownTask(task) {
        return existsInArray(this.tasks, task);
    }
    addTask(task) {
        if (!(task instanceof EventTask)) {
            if (!isObject(task)) {
                throw new EventDefinitionError("Invalid parameter type: tasks[item]");
            }
            task = new EventTask().applyExport(task);
        }
        if (this.knownTask(task)) {
            throw new EventDefinitionError("Task already known");
        }
        this.tasks = [...this.tasks, task];
        return this;
    }
    setTokens(tokens) {
        if (!isArray(tokens)) {
            throw new EventDefinitionError("Invalid parameter type: tokens");
        }
        this.tokens = [];
        for (let i = 0; i < tokens.length; i++) {
            this.addToken(tokens[i]);
        }
        return this;
    }
    removeTokenById(tokenId) {
        this.tokens = filterFromArray(this.tokens, tokenId);
        return this;
    }
    getTokenByText(tokenText) {
        return this.tokens.find((tokenObj) => {
            return tokenText.toLocaleLowerCase() === tokenObj?.getToken().toLocaleLowerCase();
        });
    }
    getTokenById(tokenId) {
        return getFromArrayById(this.tokens, tokenId);
    }
    getToken(tokenObj) {
        return getFromArray(this.tokens, tokenObj);
    }
    updateToken(tokenObj) {
        this.tokens = updateInArray(this.tokens, tokenObj);
        return this;
    }
    knownToken(token) {
        return existsInArray(this.tokens, token);
    }
    addToken(token) {
        if (!(token instanceof EventToken)) {
            if (!isObject(token)) {
                throw new EventDefinitionError("Invalid parameter type: tokens[item]");
            }
            token = new EventToken().applyExport(token);
        }
        if (this.knownToken(token)) {
            throw new EventDefinitionError("Token already known");
        }
        this.tokens = [...this.tokens, token];
        return this;
    }
    setRewards(rewards) {
        if (!isArray(rewards)) {
            throw new EventDefinitionError("Invalid parameter type: rewards");
        }
        this.rewards = [];
        for (let i = 0; i < rewards.length; i++) {
            this.addReward(rewards[i]);
        }
        return this;
    }
    removeRewardById(rewardId) {
        this.rewards = filterFromArray(this.rewards, rewardId);
        return this;
    }
    getRewardById(rewardId) {
        return getFromArrayById(this.rewards, rewardId);
    }
    getReward(rewardObj) {
        return getFromArray(this.rewards, rewardObj);
    }
    updateReward(rewardObj) {
        this.rewards = updateInArray(this.rewards, rewardObj);
        return this;
    }
    knownReward(reward) {
        return existsInArray(this.rewards, reward);
    }
    addReward(reward) {
        if (!(reward instanceof EventReward)) {
            if (!isObject(reward)) {
                throw new EventDefinitionError("Invalid parameter type: rewards[item]");
            }
            reward = new EventReward().applyExport(reward);
        }
        if (this.knownReward(reward)) {
            throw new EventDefinitionError("Reward already known");
        }
        this.rewards = [...this.rewards, reward];
        return this;
    }
}

export class EventDefinitionError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventDefinitionError";
    }
}