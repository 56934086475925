import { Participant } from "./participant/participant";
import { Notification } from "./notification";

const databaseVersion = 2;
const databaseName = "EventQuest";
const participantTableName = "Participants";
const notificationTableName = "Notifications";

let db = undefined;
let participantCache = undefined;
let participantCacheIsDirty = true;
let notificationsAreDirty = true;

export function getDB() {
    return new Promise((resolve, reject) => {
        if (db) {
            resolve(db);
            return;
        }

        const request = window.indexedDB.open(databaseName, databaseVersion);
        request.onerror = (event) => {
            reject(event); // Do something with event.target.error!
        };
        request.onsuccess = (event) => {
            db = event.target.result;
            resolve(db);
        };
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (event.oldVersion === 0) { // Creation - no old version exists yet
                db.createObjectStore(participantTableName, { keyPath: "name" });
                //db.createObjectStore(eventTableName, { keyPath: "id" });
            }
            if (!db.objectStoreNames.contains(notificationTableName)) {
                db.createObjectStore(notificationTableName, { keyPath: "id" });
            }
            // If there was a db change, event.newVersion can be compared
            // https://developer.mozilla.org/en-US/docs/Web/API/IDBVersionChangeEvent/oldVersion
        };
    });
}

export function resetDB() {
    return new Promise((resolve, reject) => {
        if (db) {
            db.close();
        }

        const request = window.indexedDB.deleteDatabase(databaseName);
        request.onsuccess = function () {
            resolve();
        };
        request.onerror = function () {
            reject();
        };
        request.onblocked = function () {
            reject();
        };
    });
}

export function getParticipantData() {
    return new Promise((resolve, reject) => {
        if (!participantCacheIsDirty && participantCache) {
            resolve(participantCache);
            return;
        }

        getDB().then((db) => {
            const participantTransaction = db.transaction([participantTableName]);

            participantTransaction.oncomplete = (event) => {
                // not needed
            };

            participantTransaction.onerror = (event) => {
                reject(event);
            };

            const participantObjectStore = participantTransaction.objectStore(participantTableName);

            participantObjectStore.openCursor().onsuccess = (event) => {
                const participantCursor = event.target.result;
                if (!participantCursor) {
                    reject(new Error("Not registered"));
                    return;
                }
                const participant = new Participant().applyExport(participantCursor.value);
                participantCache = participant;
                resolve(participantCache);
            };
        }).catch((error) => { reject(error) });
    });
}

export function participantDataExists() {
    return new Promise((resolve) => {
        getParticipantData().then(() => { resolve(true) }).catch(() => { resolve(false) })
    });
}

export function updateParticipantData(participantData) {
    return new Promise((resolve, reject) => {
        if (!(participantData instanceof Participant)) {
            reject(new Error("Not a Participant"));
        }

        participantCacheIsDirty = true; // Important to invalidate the cache the next time it is accessed

        getDB().then((db) => {
            const participantTransaction = db.transaction([participantTableName], "readwrite");

            participantTransaction.oncomplete = (event) => {
                resolve(event);
            };

            participantTransaction.onerror = (event) => {
                reject(event);
            };

            participantTransaction.objectStore(participantTableName).put(participantData);
        }).catch((error) => { reject(error) });
    });
}

export function notificationKeyAlreadyExists(notificationId) {
    return new Promise((resolve) => {
        getDB().then((db) => {
            db.transaction([notificationTableName]).objectStore(notificationTableName).get(notificationId).onsuccess = (event) => {
                resolve(!!event.target.result);
            };
        }).catch((error) => { resolve(false) });
    });
}

export function addNotification(notification) {
    return new Promise((resolve, reject) => {
        if (!(notification instanceof Notification)) {
            reject(new Error("Not a notification"));
        }

        getDB().then((db) => {
            notificationKeyAlreadyExists(notification.getId()).then((exists) => {
                if (!exists) {
                    notificationsAreDirty = true; // Important to invalidate the cache the next time it is accessed

                    const notificationTransaction = db.transaction([notificationTableName], "readwrite");

                    notificationTransaction.oncomplete = (event) => {
                        resolve(event);
                    };

                    notificationTransaction.onerror = (event) => {
                        reject(event);
                    };

                    notificationTransaction.objectStore(notificationTableName).add(notification);
                }
                resolve(); // Already exists
            });
        }).catch((error) => { reject(error) });
    });
}

export async function registerNotification(notificationData) {
    const notification = new Notification();
    if (notificationData && notificationData.hasOwnProperty("id")) {
        notification.setId(notificationData.id)
    }
    if (notificationData && notificationData.hasOwnProperty("variant")) {
        notification.setVariant(notificationData.variant);
    }
    if (notificationData && notificationData.hasOwnProperty("title")) {
        notification.setTitle(notificationData.title);
    }
    if (notificationData && notificationData.hasOwnProperty("text")) {
        notification.setText(notificationData.text);
    }
    if (notificationData && notificationData.hasOwnProperty("delay")) {
        notification.setDelay(notificationData.delay);
    }
    if (notificationData && notificationData.hasOwnProperty("display")) {
        notification.setDisplay(notificationData.display);
    }
    if (notificationData && notificationData.hasOwnProperty("data")) {
        notification.setData(notificationData.data);
    }
    if (notificationData && notificationData.hasOwnProperty("position")) {
        notification.setPosition(notificationData.position);
    }
    await addNotification(notification);
    return notification;
}

export function hasOpenNotifications() {
    return new Promise((resolve) => {
        if (!notificationsChanged()) {
            resolve(false);
            return;
        }
        countNotifications().then((count) => {
            resolve(count > 0);
        }).catch(() => {
            resolve(false);
        });
    });
}

export function notificationsChanged() {
    return notificationsAreDirty;
}

export function countNotifications() {
    return new Promise((resolve, reject) => {
        if (!notificationsAreDirty) {
            resolve(0);
            return;
        }

        getDB().then((db) => {
            const notificationTransaction = db.transaction([notificationTableName]);

            notificationTransaction.oncomplete = (event) => {
                // not needed
            };

            notificationTransaction.onerror = (event) => {
                reject(event);
            };

            const notificationObjectStore = notificationTransaction.objectStore(notificationTableName);
            notificationObjectStore.count().onsuccess = (event) => {
                resolve(event.target.result);
            };
        }).catch((error) => { reject(error) });
    });
}

export function getNotifications() {
    return new Promise((resolve, reject) => {
        if (!notificationsAreDirty) {
            resolve([]);
            return;
        }

        getDB().then((db) => {
            const notificationTransaction = db.transaction([notificationTableName]);

            notificationTransaction.oncomplete = (event) => {
                // not needed
            };

            notificationTransaction.onerror = (event) => {
                reject(event);
            };

            const notificationObjectStore = notificationTransaction.objectStore(notificationTableName);
            notificationObjectStore.getAll().onsuccess = (event) => {
                const notifications = event.target.result.map((notification) => {
                    return new Notification().applyExport(notification);
                });
                deleteNotifications().finally(() => {
                    resolve(notifications);
                });
            };
        }).catch((error) => { reject(error) });
    });
}

export function deleteNotifications() {
    return new Promise((resolve, reject) => {
        getDB().then((db) => {
            const notificationTransaction = db.transaction([notificationTableName], "readwrite");

            notificationTransaction.oncomplete = (event) => {
                notificationsAreDirty = false;
                resolve(event);
            };

            notificationTransaction.onerror = (event) => {
                reject(event);
            };

            notificationTransaction.objectStore(notificationTableName).clear();
        }).catch((error) => { reject(error) });
    });
}

/* Events Should be unused! */
/*
import { EventDefinition } from "./event/eventDefinition";

const eventTableName = "Events";

export function addEvent(eventData) {
    return new Promise((resolve, reject) => {
        if (!(eventData instanceof EventDefinition)) {
            reject(new Error("Not an Event"));
        }

        getDB().then((db) => {
            const eventTransaction = db.transaction([eventTableName], "readwrite");

            eventTransaction.oncomplete = (event) => {
                resolve(event);
            };

            eventTransaction.onerror = (event) => {
                reject(event);
            };

            eventTransaction.objectStore(eventTableName).add(eventData);
        }).catch((error) => { reject(error) });
    });
}

export function deleteEvent(eventId) {
    return new Promise((resolve, reject) => {
        getDB().then((db) => {
            const eventTransaction = db.transaction([eventTableName], "readwrite");

            eventTransaction.oncomplete = (event) => {
                resolve(event);
            };

            eventTransaction.onerror = (event) => {
                reject(event);
            };

            eventTransaction.objectStore(eventTableName).delete(eventId);
        }).catch((error) => { reject(error) });
    });
}

export function getEvent(eventId) {
    return new Promise((resolve, reject) => {
        getDB().then((db) => {
            const eventTransaction = db.transaction([eventTableName]);

            eventTransaction.oncomplete = (event) => {
                // not needed
            };

            eventTransaction.onerror = (event) => {
                reject(event);
            };

            const eventObjectStore = eventTransaction.objectStore(eventTableName);
            eventObjectStore.get(eventId).onsuccess = (event) => {
                const eventDefinition = event.target.result;
                if (!eventDefinition) {
                    reject(new Error("Unknown Event"));
                    return;
                }
                resolve(event.target.result);
            };
        }).catch((error) => { reject(error) });
    });
}
*/