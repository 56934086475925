import { useEffect, useState } from "react";
import { Badge, Button, Card, Collapse } from "react-bootstrap";

export function TaskCard({ isAvailable, lastChangeId, task, participantTask, onTaskStart, onTaskCancel, onTaskTakePicture, onTaskSelectPicture, onTaskDeletePicture }) {
    const [openImagePreview, setOpenImagePreview] = useState(false);
    const isFinished = (!!participantTask && participantTask?.getStatus()?.isFinished());
    const cardBorder = isFinished ? "border border-primary" : "";
    const hasImage = participantTask?.hasImage() || false;

    useEffect(() => {
        // If the user forgot to minimize the image preview but deletes the image - the collapse stays open
        if (hasImage) {
            setOpenImagePreview(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasImage])

    const getFooter = () => {
        if (!participantTask) {
            return <Badge bg="secondary" aria-description="Not started">Complete task to earn {task.getPoints()} points</Badge>;
        }
        if (isFinished) {
            return <Badge bg="primary" aria-description="Completed"><i className="bi bi-award-fill"></i> Completed</Badge>;
        }
        if (participantTask.getStatus().isCanceled()) {
            return <Badge bg="warning" aria-description="Canceled"><i className="bi bi-ban"></i> Canceled</Badge>;
        }
        if (participantTask.getStatus().isActive()) {
            return <Badge bg="secondary" aria-description="Can collect"><i className="bi bi-award"></i> Complete task to earn {task.getPoints()} points</Badge>;
        }
        return <Badge bg="secondary" aria-description="Not available">Unknown</Badge>;
    }
    const getNewBadge = () => {
        if (lastChangeId !== task.getId()) {
            return <></>;
        }
        return <Badge bg="primary" aria-description="New"><i className="bi bi-stars"></i> NEW</Badge>;
    }
    const getButtons = () => {
        const buttons = [];
        // If the task is directly available and the task was not yet startet show the start button
        if (isAvailable && !participantTask) {
            buttons.push(<Button key="startTaskButton" variant="outline-primary" size="sm" onClick={() => { onTaskStart(task.getId()); }}> <i className="bi bi-activity"></i> Start task</Button >);
        }
        if (!!participantTask && hasImage) {
            buttons.push(
                <Button key="showImagePreview" variant="outline-primary" size="sm" onClick={() => { setOpenImagePreview(!openImagePreview); }} className="me-1">
                    {openImagePreview ? <i className="bi bi-chevron-double-up"></i> : <i className="bi bi-chevron-double-down"></i>}
                </Button>
            );
        }
        if (isAvailable && !!participantTask && task.doesExpectImage() && participantTask.getStatus().isActive()) {
            //buttons.push(<Button key="taskTakePictureButton" variant="outline-primary" onClick={() => { onTaskTakePicture(task.getId()); }}><i className="bi bi-camera"></i> Take a photo</Button>);
            buttons.push(<Button
                key="taskSelectPictureButton"
                variant="outline-primary"
                size="sm"
                onClick={() => { onTaskSelectPicture(task.getId()); }}>
                <i className="bi bi-camera"></i> {hasImage ? "Update the image" : "Choose an image"}
            </Button>);
        }
        if (!!participantTask && hasImage && !participantTask.getStatus().isActive()) {
            buttons.push(<Button key="taskDeletePictureButton" variant="outline-danger" size="sm" onClick={() => { onTaskDeletePicture(task.getId()); }}><i className="bi bi-trash"></i></Button>);
        }
        // Currently disabled. I don't really see why anybody would have a use for this.
        //buttons.push(<Button key="cancelTaskButton" variant="outline-warning" size="sm" onClick={() => { onTaskCancel(task.getId()); }}><i className="bi bi-x-lg"></i> Cancel task</Button>);


        if (buttons.length <= 0) {
            return <></>;
        }
        return <Card.Body className="border-top">{buttons.flat()}</Card.Body>;
    }


    return (
        <Card className={`mt-3 ${cardBorder}`}>
            <Card.Body>
                <Card.Title as="h4">
                    {task.getTitle()}
                </Card.Title>
                <Card.Text>
                    {task.getDescription()}
                </Card.Text>
            </Card.Body>
            {getButtons()}
            <Collapse in={openImagePreview}>
                <div> {/* This div is required, without the opening animation is not played */}
                    <Card.Img variant="bottom" src={hasImage ? participantTask.getImage() : ""} />
                </div>
            </Collapse>
            <Card.Footer>
                <small>{getFooter()} {getNewBadge()}</small>
            </Card.Footer>
        </Card>
    )
}